.Loader {
  position: relative;
  border-radius: 9999px;
  width: 5px;
  height: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.Loader::before,
.Loader::after {
  display: inline-block;
  position: absolute;
  top: 0;
  content: "";
}

.Loader::before {
  border-radius: 9999px;
  left: -10px;
  width: 5px;
  height: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.Loader::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: currentColor;
  color: currentColor;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: currentColor;
  }
  50%,
  100% {
    background-color: currentColor;
    opacity: 0.5;
  }
}
