.typography {
  h2,
  h3 {
    margin-bottom: 0.5em;
  }

  p,
  li {
    font-size: 1em;
  }

  ul li:not(:last-child),
  ol li:not(:last-child) {
    margin-bottom: 0.15em;
  }

  p,
  ul,
  ol,
  .image,
  img {
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin-left: 1em;
  }
}
